import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import { Target } from "../../utils"
import FooterBase from "../shared/Footer"

const query = graphql`
  query {
    logo: file(relativePath: { eq: "do/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100)
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(query)
  const items = {
    name: "Do",
    subName: "就労継続支援B型",
    target: "do" as Target,
    appNum: 1010600342,
    address: "〒378-0016 群馬県利根郡沼田市清水町4208-1",
    tel: "0278-25-4772",
    fax: "0278-25-4773",
    logo: getImage(data.logo),
  }

  return <FooterBase {...items} />
}

export default Footer
